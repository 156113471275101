
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";

import KothingEditor from "kothing-editor";
import kothingConfig from "../config/editorConfig.json";
import plugins from "kothing-editor/lib/plugins";
import lang from "kothing-editor/lib/lang";
import "kothing-editor/dist/css/kothing-editor.min.css";

import { requiredRule } from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "Edit",

  data: () => ({
    requiredRule,
    kothingConfig,
    item: {} as any,
    editor_en: "" as any,
    editor_ro: "" as any,
    editor_ru: "" as any,
    currentTab: 0 as number,
    userToSendEmailTo: null as number | null,
    languages: ["en", "ro", "ru"] as Array<string>,
    flags: {
      en: require("@/assets/images/locales/en.svg"),
      ro: require("@/assets/images/locales/ro.svg"),
      ru: require("@/assets/images/locales/ru.svg")
    } as any,
    subjects: {} as any,
    editorRequirements: [[], [], []] as Array<Array<any>>,
    missingKeys: [
      [] as Array<string>,
      [] as Array<string>,
      [] as Array<string>
    ] as any,
    selects: {
      users: [] as Array<any>
    } as any,
    errorMessages: {} as { [value: string]: Array<string> },
    loading: false as boolean
  }),

  computed: {
    rules() {
      return {
        required: this.requiredRule.bind(this)
      };
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const item = await API.emailTemplates().getForEdit(Number(to.params.id));
      const variables = await API.emailTemplates().getVariables();
      const usersList = await API.users().getList();

      next(vm => {
        vm.getEditorsRequirements(variables);
        vm.item = item;
        vm.selects.users = usersList;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  mounted() {
    this.createEditor();
    this.setEditorsContent();
  },

  methods: {
    async submit(): Promise<void> {
      this.loading = true;
      try {
        // if (this.passedValidation()) {
        this.setNewEditorContent();
        await API.emailTemplates().edit(
          Number(this.$route.params.id),
          this.item
        );
        await this.$router.go(-1);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("email-templates.modals.alert.successful-edit")
        );
        // } else {
        //   await this.$store.dispatch(
        //     "alert/showError",
        //     "Did not pass validation"
        //   );
        // }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    createEditor(): void {
      const language = this.$store.getters["localization/getCurrent"];
      this.editor_en = KothingEditor.create("editor_en", {
        plugins: plugins,
        lang: (lang as any)[language],
        ...this.kothingConfig
      });
      this.editor_ro = KothingEditor.create("editor_ro", {
        plugins: plugins,
        lang: (lang as any)[language],
        ...this.kothingConfig
      });
      this.editor_ru = KothingEditor.create("editor_ru", {
        plugins: plugins,
        lang: (lang as any)[language],
        ...this.kothingConfig
      });
    },
    contains(target: any, pattern: Array<string>, index: number): boolean {
      let passed = true;
      for (const item of pattern) {
        if (!target.includes(item)) {
          this.missingKeys[index].push(item);
          passed = false;
        }
      }
      return passed;
    },
    setEditorsContent(): void {
      for (const item of this.languages) {
        this[`editor_${item}`].setContents(this.item[`content_${item}`]);
        this.subjects[`${item}`] = this.item[`subject_${item}`];
      }
    },
    setNewEditorContent(): void {
      for (const item of this.languages) {
        this.item[`content_${item}`] = this[`editor_${item}`].getContents();
      }
    },
    passedValidation(): boolean {
      let passed = true;
      let index = 0;
      this.missingKeys = [[], [], []];

      for (const item of this.languages) {
        if (
          !this.contains(
            this[`editor_${item}`].getContents(),
            this.editorRequirements[index],
            this.languages.indexOf(item)
          )
        ) {
          passed = false;
        }
        index++;
      }
      return passed;
    },
    getEditorsRequirements(value: any): void {
      this.editorRequirements = [[...value], [...value], [...value]];
    },
    async sendEmailToUser(id: number): Promise<any> {
      this.loading = true;
      try {
        if ((this.$refs.form1 as any).validate()) {
          await this.submit();
          await this.$API
            .emailTemplates()
            .sendEmail(id, Number(this.$route.params.id), {});
          this.sheet = false;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    }
  }
});
